/*-----------------------------------------------
|   Search box
-----------------------------------------------*/
.search-box{
  position: relative;
  width: 21rem;
  font-size: map-get($font-sizes, '-1');

  .search-box-icon{
    position: absolute;
    color: var(--#{$variable-prefix}400);
    top: 50%;
    left: 0.9rem;
    transform: translateY(-48%);
  }
  .search-input{
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 1.7;
    border-radius: 50rem;
    box-shadow: none;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  [data-bs-toggle="search"]:not(.show) {
    + [data-bs-dismiss="search"]{
      display: none;
    }
  }

  .file-thumbnail{
    width: 1.75rem;
    height: 1.75rem;
  }
}

.safari{
  .search-box .search-input{
    line-height: 2;
  }
}
